<template>
  <div>
    <div class="container">
      <div class="container-left">
        <div class="logo">
          <img src="./img/logo.png" alt="医疗数据管理" class="img-logo" />
          <!-- <img src="./img/logoText.svg" alt="" class="txt" /> -->
        </div>

        <div class="hos">
          <img src="./img/hos.png" alt="" />
        </div>

        <div class="copyright">
          Copyright©️2021 Tencent Inc. All Rights Reserved 腾讯公司 版权所有
        </div>
      </div>
      <div class="container-right">
        <img src="./img/rightFrame.png" alt="" />
      </div>
    </div>

    <div class="login">
      <p class="login-title">登录账号</p>

      <el-form class="login-form" ref="form" :model="formData" :rules="rules">
        <el-form-item prop="phone" class="item">
          <el-input
            class="login-input-phone"
            placeholder="请输入您的手机号"
            maxlength="11"
            @input="handlePhoneInput"
            v-model="formData.phone"
          >
            <img :src="phoneImg" slot="prefix" />
          </el-input>
        </el-form-item>

        <el-form-item prop="confirmCode" class="item">
          <el-input
            class="login-input-code"
            placeholder="请输入验证码"
            v-model="formData.confirmCode"
            @keyup.enter.native="login"
          >
            <img :src="securityImg" slot="prefix" />
            <el-button
              slot="suffix"
              class="login-confirm-code-button"
              :class="{ 'code-sty': formData.phone && waitingCaptcha === 0 }"
              type="text"
              :disabled="!confirmCodeButtonVaild"
              @click="validatePhone"
            >
              {{ countDown }}
            </el-button>
          </el-input>
        </el-form-item>

        <el-button
          :type="'primary'"
          class="login-btn"
          :class="{ 'confirm-btn': loginValid }"
          :disabled="!loginValid"
          @click="login"
          :loading="loading"
        >
          登&nbsp;&nbsp;录
        </el-button>
        <div class="auto-login">
          <el-checkbox v-model="autoLogin" class="content" :class="{ 'isChecked-sty': autoLogin }">
            自动登录
          </el-checkbox>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { PHONE_REG, ERROR_TYPE } from '@/utils/constant';
import { loadJs, getBrower, getPlatform, getDevice } from '@/utils/util';
import login from '@/libs/login';
import { SendSmsCode } from '@/api/login';
import { MdmAdminLogin, MdmUserLoginLog } from '@/api/user';
import phoneImg from './img/mobile.svg';
import securityImg from './img/security.svg';

export default {
  name: 'Login',
  data() {
    // 根据手机号的格式返回不同错误信息
    const validatePhone = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入手机号'));
      } else if (!PHONE_REG.test(value)) {
        callback(new Error('手机号格式不正确'));
      } else {
        callback();
      }
    };
    return {
      // 表单数据
      formData: {
        phone: '',
        confirmCode: '',
      },
      // 是否自动登陆
      autoLogin: false,
      // 表单验证对象
      rules: {
        // 设置手机号与验证码的合格条件
        phone: [{ validator: validatePhone, trigger: 'blur' }],
        confirmCode: [{ validator: this.validateConfirmCode, trigger: 'blur' }],
      },
      // 发送验证码倒计时与计时器
      waitingCaptcha: 0,
      waitingCaptchaTimer: null,
      // 验证码弹框
      tencentCaptcha: null,
      // 验证是否成功通过
      tencentCaptchaSuccess: false,
      // 后端返回的验证码错误信息
      confirmCodeError: null,
      // 发送请求后加载中的标识
      loading: false,
      phoneImg,
      securityImg,
    };
  },
  computed: {
    // 只有等待验证码倒计时结束后才能再次点击按钮
    confirmCodeButtonVaild() {
      return this.waitingCaptcha === 0;
    },
    // 只有成功通过滑块验证 验证码长度正确时才能激活登录按钮
    loginValid() {
      return this.tencentCaptchaSuccess && this.formData.confirmCode.length === 6;
    },
    // 发送验证码按钮的文案
    countDown() {
      if (this.waitingCaptcha === 0) {
        return '获取验证码';
      }
      return `${this.waitingCaptcha}秒后重新发送`;
    },
  },
  watch: {
    $route(to) {
      const { path } = to;
      // 路由跳转回本页面时重置所有信息
      if (path === '/login') {
        this.formData.phone = '';
        this.formData.confirmCode = '';
        this.autoLogin = false;
        this.waitingCaptcha = 0;
        this.waitingCaptchaTimer = null;
        this.tencentCaptcha = null;
        this.tencentCaptchaSuccess = false;
        this.confirmCodeError = null;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadCaptcha();
  },
  methods: {
    // 如果没有加载防水墙就进行加载
    loadCaptcha() {
      if (!window.TencentCaptcha) {
        loadJs({
          url: 'https://ssl.captcha.qq.com/TCaptcha.js',
          onload: () => {
            console.log('load TCaptcha success');
          },
        });
      }
    },
    handlePhoneInput(val) {
      // 禁止非数字值输入
      if (isNaN(val)) {
        const oldPhone = this.formData.phone.split('');
        oldPhone.pop();
        this.formData.phone = oldPhone.join('');
      }
    },
    // 检查手机号格式
    validatePhone() {
      // 重新开始验证码查错流程
      this.formData.confirmCode = '';
      this.confirmCodeError = null;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.validateBeforeSendSmsCode();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 发送验证码前先进行验证码防水墙弹出
    validateBeforeSendSmsCode() {
      if (!this.tencentCaptcha) {
        this.tencentCaptcha = new window.TencentCaptcha('2061757956', res => {
          if (res.ret !== 0) {
            return;
          }
          // 发送验证码 支持第二次发送时附带防水墙的返回值
          const sendSms = (params = {}) => {
            return SendSmsCode({ phone: this.formData.phone, sms_product: 1001, ...params }).then(
              res => {
                if (res?.error_code === 0) {
                  this.$message.success('获取验证码成功');
                  this.waitingCaptcha = 60;
                  this.waitingCaptchaTimer = setInterval(() => {
                    this.waitingCaptcha -= 1;
                    if (this.waitingCaptcha === 0) {
                      clearInterval(this.waitingCaptchaTimer);
                      this.waitingCaptchaTimer = null;
                    }
                  }, 1000);
                  this.tencentCaptchaSuccess = true;
                } else {
                  this.validateError(res, sendSms);
                }
              },
            );
          };
          sendSms().catch(err => {
            console.log(err);
            // 兼容原本验证码失败逻辑
            const message =
              err.bizcode === 4013 || err.bizcode === 98
                ? '达到验证上限，请明天再试'
                : '验证码获取失败';
            this.$message.error(message);
          });
        });
      }
      this.tencentCaptcha.show();
    },
    // 验证码请求需要安全验证时第二次弹出
    validateError(res, validCb) {
      // 疑似用户
      if (res?.error_code === 99 || res?.error_code === 98) {
        this.tencentCaptcha = new window.TencentCaptcha('2061757956', res => {
          if (res.ret !== 0) {
            return;
          }
          validCb({ ticket: res.ticket, randStr: res.randstr });
        });
        this.tencentCaptcha.show();
      }
      // 防水墙验证失败
      if (res?.error_code === 100) {
        this.$message.error('验证失败');
      }
    },
    // 验证码检查 如果有记录报错就弹出错误提示
    validateConfirmCode(rule, value, callback) {
      if (this.confirmCodeError !== null) {
        callback(new Error(this.confirmCodeError));
      } else {
        callback();
      }
    },
    // 登录
    login() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          login
            .login(
              { phone: this.formData.phone, password: this.formData.confirmCode },
              { autoLogin: this.autoLogin },
            )
            .then(res => {
              console.log(res);
              // 没有token时报错
              if (!res.token) {
                this.confirmCodeError = res.error_msg;
              } else {
                this.confirmCodeError = null;
              }
            })
            .catch(e => {
              // 验证码错误时报错
              this.confirmCodeError = ERROR_TYPE[e.bizcode];
            })
            .then(() => {
              // 查看是否有验证码报错 无报错就跳转
              this.$refs.form.validate(valid => {
                if (valid) {
                  MdmAdminLogin({ phone: this.formData.phone })
                    .then(res => {
                      this.loading = false;
                      if (res.menu_list.length !== 0) {
                        // 登陆成功后先发送日志再获取信息
                        MdmUserLoginLog({
                          device_type: getPlatform(),
                          device_name: getDevice().device,
                          browser: getBrower().appname,
                          browser_version: getBrower().version,
                          login_time: Math.floor(new Date().getTime() / 1000),
                        }).then(res => {
                          // 存储session_id为home页面获取上次登陆时间时使用
                          login.setSession(res.session_id);
                          clearInterval(this.waitingCaptchaTimer);
                          this.$router.push('/dashboard/home');
                        });
                      }
                    })
                    .catch(() => {
                      this.loading = false;
                    });
                } else {
                  this.loading = false;
                  return false;
                }
              });
            });
          return;
        }
        return false;
      });
    },
    test() {
      MdmAdminLogin({ phone: this.formData.phone });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './login.scss';
</style>
